import {isMobile} from 'react-device-detect';
import backgroundM from "./img/mobile/projects.png";
import backgroundD from "./img/desktop/projects.png";
function device() {
    if (isMobile) {
      return backgroundM
    } else {
      return backgroundD
    }
  }

function Projects() {
    return (
        <div className='projects'>
            <div className='left'>
                <h2>Проекты</h2>
                <p>
                  Работаю в сфере рекламы и дизайна с 2011 года. 
                  <br />В 2015 году стал партнёром брендингового агентства markerz. Вместе с командой дизайнеров реализовали больше 20 проектов. 
                  <br />В 2017 году  запустил собственный бренд в сфере красоты: yes!
                </p>
                <p className='highlights'>
                    Специализируюсь на: 
                    {"\n"}— Бренд-менеджмент
                    {"\n"}— Продвижение
                    {"\n"}— Запуск новых продуктов
                    {"\n"}— Дизайн упаковки
                    {"\n"}— Управление проектами
                    {"\n"}— Нейминг 
                </p>
            </div>
            <div className='right'>
                <img src={device()} alt=''/>
            </div>
        </div>
    );
  }
  
  export default Projects;