import main from "./img/welport/main.png";
import desktop from "./img/welport/desktop.png";
import mobile from "./img/welport/mobile.png";
import Project from './Project'

function Welport(props) {
    const mainImg = main;
    const mobileImg = [mobile]
    const desktopImg = [desktop]
    const link = '/welport'
    const full = props.full
    const name = 'Welport'
    const text = `Ребрендинг производителя алюминиевых конструкций: окон, ворот и роллет.
    Название позволяет встать в один ряд с крупными компаниями производителями.
    Логотип объединяет три метафоры: высокие технологии, заботу и форму самих изделия (окна, ворота и роллеты).
    Знак легко масштабируется в паттерн и превращается в основной идентификатор.`
    const skills = [
        'Нейминг',
        'Визуальная идентификация',
        'Рекламные материалы'
    ]
    return (
        <Project {...{mainImg,desktopImg,mobileImg,link,full,name,text,skills}} />
    );
  }
  
  export default Welport;