import {isMobile} from 'react-device-detect';
import backgroundM from './img/mobile/savinski.png'
import backgroundD from './img/desktop/savinski.png'
function device() {
  if (isMobile) {
    return backgroundM
  } else {
    return backgroundD
  }
}

function Header() {
    return (
      <div id="header" style={{ backgroundImage: `url(${device()})` }}>
        <h1>
          Никита
          <br/>Савинский
        </h1>
        <h3>Brand Management / Art Direction</h3>
        <div className='contacts'>
          <a href='http://t.me/sawinski'>Написать в телеграм</a>
          <p className='highlights'>
            +7 (999) 642-00-49
            {'\n'}nisawinski@gmail.com
          </p>
        </div>
      </div>
    );
  }
  
  export default Header;