import orderum from "./img/mobile/orderum.png";
import orderum1D from "./img/desktop/orderum1.png";
import orderum1 from "./img/mobile/orderum1.png";
import Project from './Project'

function Orderum(props) {
    const mobileImg = [orderum1]
    const desktopImg = [orderum1D]
    const mainImg = orderum
    const link = 'orderum'
    const full = props.full
    const name = 'Orderum'
    const text = `Профессиональная строительная команда Orderum открывает коммерческие объекты
    в крупных торговых центрах. В портфолио магазины брендов: Nike, Re:store, Samsung, Lego, Ascona и другие региональные бренды.
    Orderum звучит жестко. Это характерно для строительных фирм, и не даёт спутать отрасль компании. Order — это порядок.
    Жесткие линии шрифта с засечками напоминают форму колоны.
    Фирменный ордер — схематичное изображение колонны.`
    const skills = [
        'Нейминг',
        'Визуальная идентификация',
        'Сайт'
    ]
    return (
        <Project {...{mainImg,desktopImg,mobileImg,link,full,name,text,skills}} />
    );
  }
  
  export default Orderum;