import React, { useEffect } from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";

import Header from './Header';  
import Projects from './Projects';
import Back from './Back'

import Fromthemoon from './projects/Fromthemoon';
import Yes from './projects/Yes';
import Kit from './projects/Kit';
import Friday from './projects/Friday';
import Orderum from './projects/Orderum'
import Struktura from './projects/Struktura'
import Welport from './projects/Welport'
import Raybor from './projects/Raybor'

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}


function App() {
  return (
    <Router>
      <Route 
        render={({ location }) => location.pathname.length > 1
            ? <React.Fragment><ScrollToTop /> <Back /></React.Fragment>
            : null
      }
      />
      <div className="App">
      <Switch>
          <Route path="/yes">
            <Yes full={true}/>
          </Route>
          <Route path="/fromthemoon">
            <Fromthemoon full={true} />
          </Route>
          <Route path="/kit">
            <Kit full={true} />
          </Route>
          <Route path="/friday">
            <Friday full={true} />
          </Route>
          <Route path="/orderum">
            <Orderum full={true} />
          </Route>
          <Route path="/struktura">
            <Struktura full={true} />
          </Route>
          <Route path="/welport">
            <Welport full={true} />
          </Route>
          <Route path="/raybor">
            <Raybor full={true} />
          </Route>

          <Route path="/">
            <Header />
            <Projects  />
            <Fromthemoon />
            <Yes />
            <Struktura />
            <Raybor />
            <Friday />
            <Orderum />
            <Welport />
            <Kit />
          </Route>
      </Switch>
      </div>
    </Router>
  );
}

export default App;

