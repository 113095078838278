import { Link } from "react-router-dom";
import {BrowserView, MobileView} from 'react-device-detect';

function Project(props) {
    const skills = props.skills.map((skill) => 
        '\n' + skill
    );
    const description = (
        <div className='left'>
            {!props.full ? <Link className='underline' to={props.link}><h2>{props.name}</h2></Link> : <h2 className='paddingTop'>{props.name}</h2>}
            <p>{props.text}</p>
            <p className='highlights'>{skills}</p>
        </div>
    )
    const mobileImg = props.mobileImg.map((img, i) => 
        <img src={img} alt={i} key={i}/>
    );
    const desktopImg = props.desktopImg.map((img, i) => 
        <img src={img} alt={i} key={i}/>
    );
    const link = props.link;
    const mainImg = props.full ? <img src={props.mainImg} alt={props.mainImg}/> : <Link to={link}><img src={props.mainImg} alt={props.mainImg}/></Link>
    return (
        <div className='projects'>
            <BrowserView>
                {description}
            </BrowserView>
            <div className='right'>
                {mainImg}
            </div>
            <MobileView>
                {description}
            </MobileView>

            {props.full &&
                <div>
                    <MobileView>
                        {mobileImg}
                    </MobileView>
                    <BrowserView>
                        {desktopImg}
                    </BrowserView>
                </div>
            }
        </div>
    );
  }
  
  export default Project;