import { Link } from "react-router-dom";
import {BrowserView, MobileView} from 'react-device-detect';
import yes from "./img/mobile/yes.png";
import yes4 from "./img/mobile/yes4.png";

import mobileweb from "./img/yes/mobileweb.png";
import mobile1 from "./img/yes/mobile1.png";
import mobile2 from "./img/yes/mobile2.png";
import desktopweb from "./img/yes/desktopweb.png";
import desktop1 from "./img/yes/desktop1.png";
import desktop2 from "./img/yes/desktop2.png";
import desktop3 from "./img/yes/desktop3.png";

import video1 from "./img/mobile/yes3.mp4";
import video2 from "./img/mobile/yes6.mp4";
import gif1 from "./img/mobile/yes3.gif";
import gif2 from "./img/mobile/yes6.gif";

function Description(props) {
    return (
        <div className='left'>
            {props.link && <Link className='underline' to='/yes'><h2>yes!</h2></Link>}
            {!props.link && <h2 className='paddingTop'>yes!</h2>}
            <p>Провокационный бренд сахарной пасты и косметики для эпиляции. Яркая и сочная идентификация радикально отличается от привычного в сфере шугаринга. Запущено 2 серии: yes! Color, yes! Bloom</p>
            <p className='highlights'> 
                {"\n"}Нейминг 
                {"\n"}Визуальная идентификация
                {"\n"}E-commerce
                {"\n"}Маркетплейсы
                {"\n"}Продвижение
                {"\n"}Рекламные материалы
                {"\n"}Сайт
            </p>
    </div>
    )
}

function Yes(props) {
    return (
        <div className='projects'>
            <BrowserView>
                <Description link={!props.full ? true : false}/>
            </BrowserView>
            <div className='right'>
                <div className='container'>
                    <Link to='/yes'>
                        <img src={yes} alt=''/>
                    </Link>
                </div>
            </div>
            {!props.full &&
                <MobileView>
                    <Description link={true}/>
                </MobileView>
            }
            {props.full &&
                <div>
                    <MobileView>
                        <Description/>
                        <img src={mobile1} alt=''/>
                        <img src={mobile2} alt=''/>
                        <img src={gif1} alt=''/>
                        <img src={mobileweb} alt=''/>
                        <img src={yes4} alt=''/>
                        <img src={gif2} alt=''/>
                    </MobileView>
                    <BrowserView>
                        <img src={desktop1} alt=''/>
                        <div className='row'>
                            <video style={{width: '35%'}} className='threethree' autoPlay loop muted>
                                <source src={video1} type="video/mp4"></source>
                            </video>
                            <img style={{width: '64%'}} className='twothree' src={desktop2} alt=''/>
                        </div>
                        <img src={desktopweb} alt=''/>
                        <div className='third'>
                            <img style={{width: '64%', height: '50%', marginTop: '60px'}} src={desktop3} alt=''/>
                            <video style={{width: '35%'}} autoPlay loop muted>
                                <source src={video2} type="video/mp4"></source>
                            </video>
                        </div>
                    </BrowserView>
                </div>
            }
        </div>
    );
  }
  
  export default Yes;