import { Link } from "react-router-dom";
import {BrowserView, MobileView} from 'react-device-detect';
import main from "./img/fromthemoon/main.png";
import gif from "./img/fromthemoon/logo.gif";

import mobile1 from "./img/fromthemoon/mobile1.png";
import mobile2 from "./img/fromthemoon/mobile2.png";
import mobile3 from "./img/fromthemoon/mobile3.png";
import mobile6 from "./img/fromthemoon/mobile6.png";
import mobileBag from "./img/fromthemoon/mobileBag.png";
import mobileApp from "./img/fromthemoon/mobileApp.png";
import mobileHoody from "./img/fromthemoon/mobileHoody.png";

import desktop1 from "./img/fromthemoon/desktop1.png";
import desktop2 from "./img/fromthemoon/desktop2.png";
import desktop5 from "./img/fromthemoon/desktop5.png";
import desktopBag from "./img/fromthemoon/desktopBag.png";
import desktopHoody from "./img/fromthemoon/desktopHoody.png";

import video from "./img/fromthemoon/video1.mp4";
import animation from "./img/fromthemoon/animation.gif";

function Description(props) {
    return (
        <div className='left'>
            {props.link && <Link className='underline' to='/fromthemoon'><h2>fromthemoon</h2></Link>}
            {!props.link && <h2 className='paddingTop'>fromthemoon</h2>}
            <p>Новаторский бренд ухода за кожей. Минималистичная упаковка сочетается с минималистичными составами. Название значительно отличается от общей картины в сфере бьюти.</p>
            <p className='highlights'> 
                {"\n"}Нейминг 
                {"\n"}Визуальная идентификация
                {"\n"}3D визуализация
                {"\n"}Маркетплейсы
                {"\n"}Продвижение
                {"\n"}Рекламные материалы
            </p>
        </div>
    )
}

function Fromthemoon(props) {
    const mainImg = !props.full ? main : gif
    return (
        <div className='projects'>
            <BrowserView>
                <Description link={!props.full ? true : false}/>
            </BrowserView>
            <div className='right'>
                <Link to='/fromthemoon'>
                    <img src={mainImg} alt=''/>
                </Link>
            </div>
            {!props.full &&
                <MobileView>
                    <Description link={true}/>
                </MobileView>
            }
            {props.full &&
            <div>
                <MobileView>
                    <Description/>
                    <img src={mobile1} alt=''/>
                    <img src={mobile2} alt=''/>
                    <img src={mobile3} alt=''/>
                    <img src={mobileBag} alt=''/>
                    <img src={mobileApp} alt=''/>
                    <img src={mobileHoody} alt=''/>
                    <img src={animation} alt=''/>
                    <img src={mobile6} alt=''/>
                </MobileView>
                <BrowserView>
                    <img src={desktop1} alt=''/>
                    <img src={desktopBag} alt=''/>
                    <div className='row'>
                        <img className='twothree' src={desktop2} alt=''/>
                        <video className='threethree' autoPlay loop muted>
                            <source src={video} type="video/mp4"></source>
                        </video>
                    </div>
                    <img src={desktopHoody} alt=''/>
                    <img src={desktop5} alt=''/>
                </BrowserView>
            </div>
            }
        </div>
    );
  }
  
  export default Fromthemoon;