import mainImg from "./img/struktura/main.png";
import desktop from "./img/struktura/desktop.png";
import mobile from "./img/struktura/mobile.png";
import Project from './Project'

function Struktura(props) {
    const mobileImg = [mobile]
    const desktopImg = [desktop]
    const link = '/struktura'
    const full = props.full
    const name = 'Struktura'
    const text = `Ребрендинг строительной компании.
    Структура — это уникальное, простое название с подходящими ассоциациями.
    Динамичный логотип узнаётся во всех вариантах. 
    Строгий брутальный фирменный стиль основан сочетании чёрного и красного, схематичных изображений строений и линий.`
    const skills = [
        'Нейминг',
        'Визуальная идентификация',
        'Сайт'
    ]
    return (
        <Project {...{mainImg,desktopImg,mobileImg,link,full,name,text,skills}} />
    );
  }
  
  export default Struktura;