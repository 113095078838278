import { Link } from "react-router-dom";
import {BrowserView, MobileView} from 'react-device-detect';
import kit from "./img/mobile/kit.png";
import kit1 from "./img/mobile/kit1.png";
import gif from "./img/mobile/kit.gif";
import kit1D from "./img/desktop/kit1.png";

function Description(props) {
    return (
        <div className='left'>
            {props.link && <Link className='underline' to='/kit'><h2>KiT</h2></Link>}
            {!props.link && <h2 className='paddingTop'>KiT</h2>}
            <p>Ребрендинг производителя кухонь. Вдохнуть новую жизнь в бренд, сохранив узнаваемость. Мы сохранили самые узнаваемые элементы айдентики: цветовую схему и строчную “i”. Шкафчики — новый элемент стиля. Они легко масштабируются и узнаются даже без названия. Идентификация стала проще, чище и современнее.</p>
            <p className='highlights'> 
                {"\n"}Визуальная идентификация
                {"\n"}Рекламные материалы
            </p>
        </div>
    )
}

function Kit(props) {
    return (
        <div className='projects'>
            <BrowserView>
                <Description link={!props.full ? true : false}/>
            </BrowserView>
            {!props.full &&
                <div>
                    <div className='right'>
                        <Link to='/kit'>
                            <img src={kit} alt=''/>
                        </Link>
                    </div>
                    <MobileView>
                        <Description link={true}/>
                    </MobileView>
                </div>
            }
            {props.full &&
                <div>
                    <div className='right'>
                        <img src={gif} alt=''/>
                    </div>    
                    <MobileView>
                        <Description />
                        <img src={kit1} alt=''/>
                    </MobileView>
                    <BrowserView>
                        <img src={kit1D} alt=''/>
                    </BrowserView>
                    <iframe
                        src={`https://www.youtube.com/embed/jjqGd8YlSRk`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                    />
                </div>
            }
        </div>
    );
  }
  
  export default Kit;