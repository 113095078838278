import main from "./img/raybor/main.png";
import desktop from "./img/raybor/desktop.png";
import mobile from "./img/raybor/mobile.png";
import Project from './Project'

function Raybor(props) {
    const mainImg = main;
    const mobileImg = [mobile]
    const desktopImg = [desktop]
    const link = '/raybor'
    const full = props.full
    const name = 'Raybor'
    const text = `Ребрендинг производителя стеклянных огнеупорных конструкций.
    Raybor — уникальное название, которое отличается от названий конкурентов, созвучно со словом Armour (защита) и звучит жёстко.
    Для логотипа разработан шрифт —технологичный гротеск, который просто читается и современно выглядит.
    Фирменный символ очертания стекла с контрастом прямых линий и плавных углов можно использовать в качестве фона.`
    const skills = [
        'Нейминг',
        'Визуальная идентификация',
        'Рекламные материалы'
    ]
    return (
        <Project {...{mainImg,desktopImg,mobileImg,link,full,name,text,skills}} />
    );
  }
  
  export default Raybor;