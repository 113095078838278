import { Link } from "react-router-dom";
import {BrowserView, MobileView} from 'react-device-detect';
import friday from "./img/mobile/friday.png";
import gif from "./img/mobile/friday.gif";
import friday1 from "./img/mobile/friday1.png";
import friday1D from "./img/desktop/friday1.png";

function Description(props) {
    return (
        <div className='left'>
            {props.link && <Link className='underline' to='/friday'><h2>Фрайдэй</h2></Link>}
            {!props.link && <h2 className='paddingTop'>Фрайдэй</h2>}
            <p>
                Новый необычный продукт: голандский картофель с топпингами. 
                Простое название отличается от названий конкурентов, легко запоминается и даёт пространство для креатива. 
                Айдентика строится на ассоциациях с продуктом: фирменные цвета, паттерн-соус, иконка-упаковка.
            </p>
            <p className='highlights'> 
                {"\n"}Нейминг
                {"\n"}Визуальная идентификация
                {"\n"}Рекламные материалы
            </p>
        </div>
    )
}

function Friday(props) {
    return (
        <div className='projects'>
            <BrowserView>
                <Description link={!props.full ? true : false}/>
            </BrowserView>
            {!props.full &&
                <div>
                    <div className='right'>
                        <Link to='/friday'>
                            <img src={friday} alt=''/>
                        </Link>
                    </div>
                    <MobileView>
                        <Description link={true}/>
                    </MobileView>
                </div>
            }
            {props.full &&
                <div>
                    <div className='right'>
                        <img src={gif} alt=''/>
                    </div>
                    <MobileView>
                        <Description />
                        <img src={friday1} alt=''/>
                    </MobileView>
                    <BrowserView>
                        <img src={friday1D} alt=''/>
                    </BrowserView>
                </div>
            }
        </div>
    );
  }
  
  export default Friday;